/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceReportBasicSchema } from '../models/AcceptanceReportBasicSchema';
import type { AcceptanceReportListViewSchema } from '../models/AcceptanceReportListViewSchema';
import type { AcceptanceReportSchema } from '../models/AcceptanceReportSchema';
import type { CarCollectionSchema } from '../models/CarCollectionSchema';
import type { CarMakeCollectionSchema } from '../models/CarMakeCollectionSchema';
import type { ClientAddSchema } from '../models/ClientAddSchema';
import type { ClientAlphabetListSchema } from '../models/ClientAlphabetListSchema';
import type { ClientCarAddSchema } from '../models/ClientCarAddSchema';
import type { ClientCarBasicSchema } from '../models/ClientCarBasicSchema';
import type { ClientCarCollectionSchema } from '../models/ClientCarCollectionSchema';
import type { ClientCarSchema } from '../models/ClientCarSchema';
import type { ClientInfoSchema } from '../models/ClientInfoSchema';
import type { ClientListBoxSchema } from '../models/ClientListBoxSchema';
import type { ClientNoteAddSchema } from '../models/ClientNoteAddSchema';
import type { ClientNoteCollectionSchema } from '../models/ClientNoteCollectionSchema';
import type { ClientOrderListViewSchema } from '../models/ClientOrderListViewSchema';
import type { ClientSchema } from '../models/ClientSchema';
import type { ClientWorkplaceCollectionSchema } from '../models/ClientWorkplaceCollectionSchema';
import type { ClientWorkplaceFilterSchema } from '../models/ClientWorkplaceFilterSchema';
import type { CompanyFieldCollectionSchema } from '../models/CompanyFieldCollectionSchema';
import type { CompanyFieldFilterSchema } from '../models/CompanyFieldFilterSchema';
import type { CompanySchema } from '../models/CompanySchema';
import type { CorrectionBasicSchema } from '../models/CorrectionBasicSchema';
import type { CorrectionListViewSchema } from '../models/CorrectionListViewSchema';
import type { CorrectionSchema } from '../models/CorrectionSchema';
import type { CrewCollectionSchema } from '../models/CrewCollectionSchema';
import type { CrewSaveSchema } from '../models/CrewSaveSchema';
import type { DocumentElementAddSchema } from '../models/DocumentElementAddSchema';
import type { DocumentListSchema } from '../models/DocumentListSchema';
import type { DocumentSectionAddSchema } from '../models/DocumentSectionAddSchema';
import type { EngineCollectionSchema } from '../models/EngineCollectionSchema';
import type { FilterDateField } from '../models/FilterDateField';
import type { FilterSelectField } from '../models/FilterSelectField';
import type { FuelCollectionSchema } from '../models/FuelCollectionSchema';
import type { HolidayCollectionSchema } from '../models/HolidayCollectionSchema';
import type { HolidayFilterSchema } from '../models/HolidayFilterSchema';
import type { HolidaySchema } from '../models/HolidaySchema';
import type { IncomeBasicSchema } from '../models/IncomeBasicSchema';
import type { IncomeDocumentTypeCollection } from '../models/IncomeDocumentTypeCollection';
import type { IncomeItemListViewSchema } from '../models/IncomeItemListViewSchema';
import type { IncomeListViewSchema } from '../models/IncomeListViewSchema';
import type { IncomeSchema } from '../models/IncomeSchema';
import type { InvoiceBasicSchema } from '../models/InvoiceBasicSchema';
import type { InvoiceItemListViewSchema } from '../models/InvoiceItemListViewSchema';
import type { InvoiceListViewSchema } from '../models/InvoiceListViewSchema';
import type { InvoiceSchema } from '../models/InvoiceSchema';
import type { KeepFileSchema } from '../models/KeepFileSchema';
import type { LegalEntityCollectionSchema } from '../models/LegalEntityCollectionSchema';
import type { LegalEntityFilterSchema } from '../models/LegalEntityFilterSchema';
import type { LegalEntitySchema } from '../models/LegalEntitySchema';
import type { ListColumnSchema } from '../models/ListColumnSchema';
import type { LocationBasicSchema } from '../models/LocationBasicSchema';
import type { LocationCollectionSchema } from '../models/LocationCollectionSchema';
import type { LocationCrewsSchema } from '../models/LocationCrewsSchema';
import type { LocationSettingsSchema } from '../models/LocationSettingsSchema';
import type { LocationShiftCalendarSchema } from '../models/LocationShiftCalendarSchema';
import type { LocationStoragesSchema } from '../models/LocationStoragesSchema';
import type { LocationWorkPlacesSchema } from '../models/LocationWorkPlacesSchema';
import type { OrderBasicSchema } from '../models/OrderBasicSchema';
import type { OrderCommentAddSchema } from '../models/OrderCommentAddSchema';
import type { OrderCommentCollectionSchema } from '../models/OrderCommentCollectionSchema';
import type { OrderDefectBasicSchema } from '../models/OrderDefectBasicSchema';
import type { OrderDefectJobBasicSchema } from '../models/OrderDefectJobBasicSchema';
import type { OrderDefectJobListViewSchema } from '../models/OrderDefectJobListViewSchema';
import type { OrderDefectJobSchema } from '../models/OrderDefectJobSchema';
import type { OrderDefectListViewSchema } from '../models/OrderDefectListViewSchema';
import type { OrderDefectPartBasicSchema } from '../models/OrderDefectPartBasicSchema';
import type { OrderDefectPartListViewSchema } from '../models/OrderDefectPartListViewSchema';
import type { OrderDefectPartSchema } from '../models/OrderDefectPartSchema';
import type { OrderDefectPriorityBasicSchema } from '../models/OrderDefectPriorityBasicSchema';
import type { OrderDefectPrioritySchema } from '../models/OrderDefectPrioritySchema';
import type { OrderDefectSchema } from '../models/OrderDefectSchema';
import type { OrderDefectStatusBasicSchema } from '../models/OrderDefectStatusBasicSchema';
import type { OrderDefectStatusSchema } from '../models/OrderDefectStatusSchema';
import type { OrderJobBasicSchema } from '../models/OrderJobBasicSchema';
import type { OrderJobListViewSchema } from '../models/OrderJobListViewSchema';
import type { OrderJobSchema } from '../models/OrderJobSchema';
import type { OrderJobSummarySchema } from '../models/OrderJobSummarySchema';
import type { OrderKanbanSchema } from '../models/OrderKanbanSchema';
import type { OrderListBoxSchema } from '../models/OrderListBoxSchema';
import type { OrderListViewSchema } from '../models/OrderListViewSchema';
import type { OrderPartBasicSchema } from '../models/OrderPartBasicSchema';
import type { OrderPartListViewSchema } from '../models/OrderPartListViewSchema';
import type { OrderPartSchema } from '../models/OrderPartSchema';
import type { OrderPartSummarySchema } from '../models/OrderPartSummarySchema';
import type { OrderRecommendBasicSchema } from '../models/OrderRecommendBasicSchema';
import type { OrderRecommendListViewSchema } from '../models/OrderRecommendListViewSchema';
import type { OrderRecommendSummarySchema } from '../models/OrderRecommendSummarySchema';
import type { OrderSchema } from '../models/OrderSchema';
import type { OrderStatusAddSchema } from '../models/OrderStatusAddSchema';
import type { OrderStatusSchema } from '../models/OrderStatusSchema';
import type { OrderWorkListAddSchema } from '../models/OrderWorkListAddSchema';
import type { OrderWorkListListViewSchema } from '../models/OrderWorkListListViewSchema';
import type { PaginatorTemplate } from '../models/PaginatorTemplate';
import type { PartBasicSchema } from '../models/PartBasicSchema';
import type { PartBrandCollectionSchema } from '../models/PartBrandCollectionSchema';
import type { PartListBoxSchema } from '../models/PartListBoxSchema';
import type { PartListViewSchema } from '../models/PartListViewSchema';
import type { PartOrderBasicSchema } from '../models/PartOrderBasicSchema';
import type { PartOrderItemListViewSchema } from '../models/PartOrderItemListViewSchema';
import type { PartOrderListViewSchema } from '../models/PartOrderListViewSchema';
import type { PartOrderSchema } from '../models/PartOrderSchema';
import type { PartReserveBasicSchema } from '../models/PartReserveBasicSchema';
import type { PartReserveListViewSchema } from '../models/PartReserveListViewSchema';
import type { PartReserveSchema } from '../models/PartReserveSchema';
import type { PartSchema } from '../models/PartSchema';
import type { PlannerFirstAddSchema } from '../models/PlannerFirstAddSchema';
import type { PlannerFirstDataSchema } from '../models/PlannerFirstDataSchema';
import type { PlannerItemAddSchema } from '../models/PlannerItemAddSchema';
import type { PlannerItemDataSchema } from '../models/PlannerItemDataSchema';
import type { PlannerLocationSchema } from '../models/PlannerLocationSchema';
import type { PlannerNotificationBasicSchema } from '../models/PlannerNotificationBasicSchema';
import type { PlannerNotificationSchema } from '../models/PlannerNotificationSchema';
import type { PlannerReserveAddSchema } from '../models/PlannerReserveAddSchema';
import type { PlannerReserveDataSchema } from '../models/PlannerReserveDataSchema';
import type { PostAddSchema } from '../models/PostAddSchema';
import type { PostCollectionSchema } from '../models/PostCollectionSchema';
import type { PriceListCollectionSchema } from '../models/PriceListCollectionSchema';
import type { PriceListFilterSchema } from '../models/PriceListFilterSchema';
import type { PriceListItemAddSchema } from '../models/PriceListItemAddSchema';
import type { PriceListItemCollectionSchema } from '../models/PriceListItemCollectionSchema';
import type { PriceListItemFilterSchema } from '../models/PriceListItemFilterSchema';
import type { PriceListSchema } from '../models/PriceListSchema';
import type { RackBasicSchema } from '../models/RackBasicSchema';
import type { RackCollectionSchema } from '../models/RackCollectionSchema';
import type { RegisterBasicSchema } from '../models/RegisterBasicSchema';
import type { RegisterListBoxSchema } from '../models/RegisterListBoxSchema';
import type { RegisterListViewSchema } from '../models/RegisterListViewSchema';
import type { RegisterSchema } from '../models/RegisterSchema';
import type { RoleCollectionSchema } from '../models/RoleCollectionSchema';
import type { ShiftCalendarAddSchema } from '../models/ShiftCalendarAddSchema';
import type { ShiftCalendarRenderResultSchema } from '../models/ShiftCalendarRenderResultSchema';
import type { ShiftCalendarRenderSchema } from '../models/ShiftCalendarRenderSchema';
import type { StorageAddSchema } from '../models/StorageAddSchema';
import type { StorageCollectionSchema } from '../models/StorageCollectionSchema';
import type { TaxationCollectionSchema } from '../models/TaxationCollectionSchema';
import type { TaxationFilterSchema } from '../models/TaxationFilterSchema';
import type { TaxationSchema } from '../models/TaxationSchema';
import type { TimeZoneTemplate } from '../models/TimeZoneTemplate';
import type { TransactionBasicSchema } from '../models/TransactionBasicSchema';
import type { TransactionListViewSchema } from '../models/TransactionListViewSchema';
import type { TransactionSchema } from '../models/TransactionSchema';
import type { TransactionTypeBasicSchema } from '../models/TransactionTypeBasicSchema';
import type { TransactionTypeCollectionSchema } from '../models/TransactionTypeCollectionSchema';
import type { TransferBasicSchema } from '../models/TransferBasicSchema';
import type { TransferListViewSchema } from '../models/TransferListViewSchema';
import type { TransferSchema } from '../models/TransferSchema';
import type { TransmissionCollectionSchema } from '../models/TransmissionCollectionSchema';
import type { UnitCollectionSchema } from '../models/UnitCollectionSchema';
import type { UploadedFileSchema } from '../models/UploadedFileSchema';
import type { UserAddSchema } from '../models/UserAddSchema';
import type { UserAlphabetListSchema } from '../models/UserAlphabetListSchema';
import type { UserAuthorizedSchema } from '../models/UserAuthorizedSchema';
import type { UserCreateAdminSchema } from '../models/UserCreateAdminSchema';
import type { UserDataTemplate } from '../models/UserDataTemplate';
import type { UserSchema } from '../models/UserSchema';
import type { VehicleTypeCollectionSchema } from '../models/VehicleTypeCollectionSchema';
import type { WorkPlaceAddSchema } from '../models/WorkPlaceAddSchema';
import type { WorkPlaceCollectionSchema } from '../models/WorkPlaceCollectionSchema';
import type { WorkZoneAddSchema } from '../models/WorkZoneAddSchema';
import type { WorkZoneCollectionSchema } from '../models/WorkZoneCollectionSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class Service {
    /**
     * Данные акта приёма
     * Данные акта приёма
     * @returns any Успешно
     * @throws ApiError
     */
    public static acceptanceReportGet({
        id,
    }: {
        /**
         * id акта приёма
         */
        id: any,
    }): CancelablePromise<{
        report?: AcceptanceReportSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/acceptance-report/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение акта приёма
     * Изменяет акт приёма
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static acceptanceReportUpdate({
        id,
        requestBody,
    }: {
        /**
         * id акта приёма
         */
        id: any,
        requestBody?: AcceptanceReportBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/acceptance-report/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление акта приёма
     * Удаляет указанный акт приёма
     * @returns void
     * @throws ApiError
     */
    public static acceptanceReportDelete({
        id,
    }: {
        /**
         * id акта приёма
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/acceptance-report/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список актов приёма
     * Возвращает отфильтрованный список актов приёма
     * @returns any Результат получен
     * @throws ApiError
     */
    public static acceptanceReportIndex({
        order,
        carMake,
        carModel,
        employee,
        date,
        query,
        page,
        limit,
    }: {
        /**
         * ID заказ-наряда
         */
        order?: any,
        /**
         * ID марки автомобиля
         */
        carMake?: any,
        /**
         * ID модели автомобиля
         */
        carModel?: any,
        /**
         * ID ответственного
         */
        employee?: any,
        /**
         * Дата осмотра
         */
        date?: any,
        /**
         * Поиск по след. полям: номер, марка, модель, VIN, фамилия ответственного
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Акты приёма
         */
        items?: Array<AcceptanceReportListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/acceptance-reports',
            query: {
                'order': order,
                'car_make': carMake,
                'car_model': carModel,
                'employee': employee,
                'date': date,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление акта приёма
     * Добавляет акт приёма
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static acceptanceReportAdd({
        requestBody,
    }: {
        requestBody?: AcceptanceReportBasicSchema,
    }): CancelablePromise<{
        message?: string;
        reportId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/acceptance-report',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Список автомобилей
     * Возвращает отфильтрованный список автомобилей
     * @returns CarCollectionSchema Результат получен
     * @throws ApiError
     */
    public static carIndex({
        query,
        carMake,
    }: {
        /**
         * Ищет вхождение указанной подстроки
         */
        query?: any,
        /**
         * Марка автомобиля
         */
        carMake?: any,
    }): CancelablePromise<CarCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/cars',
            query: {
                'query': query,
                'car_make': carMake,
            },
        });
    }
    /**
     * Список марок автомобилей
     * Возвращает отфильтрованный список марок автомобилей
     * @returns CarMakeCollectionSchema Результат получен
     * @throws ApiError
     */
    public static carMakeIndex({
        name,
    }: {
        /**
         * Поиск марки по названию
         */
        name?: any,
    }): CancelablePromise<CarMakeCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/car/makes',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Список автомобилей клиента
     * Возвращает отфильтрованный список автомобилей клиента
     * @returns ClientCarCollectionSchema Результат получен
     * @throws ApiError
     */
    public static clientCarIndex({
        name,
        license,
        vin,
        client,
        query,
    }: {
        /**
         * Фильтр по названию
         */
        name?: any,
        /**
         * Фильтр по гос. номеру
         */
        license?: any,
        /**
         * Фильтр по vin
         */
        vin?: any,
        /**
         * Фильтр по id клиента
         */
        client?: any,
        /**
         * Фильтр по полям: номер, VIN, марка, модель
         */
        query?: any,
    }): CancelablePromise<ClientCarCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client-cars',
            query: {
                'name': name,
                'license': license,
                'vin': vin,
                'client': client,
                'query': query,
            },
        });
    }
    /**
     * Автомобиль клиента
     * Данные автомобиля клиента
     * @returns any Результат получен
     * @throws ApiError
     */
    public static clientCarGet({
        id,
    }: {
        /**
         * id автомобиля клиента
         */
        id: any,
    }): CancelablePromise<{
        car?: ClientCarSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client-car/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Обновление автомобиля клиента
     * Обновляет указанный автомобиль клиента
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static clientCarUpdate({
        id,
        requestBody,
    }: {
        /**
         * id автомобиля
         */
        id: any,
        requestBody?: ClientCarAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/client-car/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление автомобиля клинта
     * Удаляет указанный автомобиль клиента
     * @returns void
     * @throws ApiError
     */
    public static clientCarDelete({
        id,
    }: {
        /**
         * id автомобиля
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/client-car/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Добавление автомобиля клиента
     * Добавляет автомобиль клиента
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static clientCarAdd({
        requestBody,
    }: {
        requestBody?: ClientCarAddSchema,
    }): CancelablePromise<{
        message?: string;
        clientCarId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client-car',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Устанавливает изображение автомобиля клиента
     * Устанавливает изображение автомобиля клиента
     * @returns any Изображение успешно сохранено
     * @throws ApiError
     */
    public static clientCarSetPicture({
        id,
        formData,
    }: {
        /**
         * id автомобиля клиента
         */
        id: any,
        formData: {
            /**
             * Файл изображения
             */
            picture: Blob;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client-car/{id}/picture',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Список клиентов
     * Возвращает отфильтрованный список клиентов
     * @returns any Результат получен
     * @throws ApiError
     */
    public static clientIndex({
        state,
        id,
        query,
        car,
        viewMode,
    }: {
        /**
         * Тип клиента
         */
        state?: 'all' | 'favorites' | 'blacklist' | 'archive',
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Ищет вхождение указанной подстроки в след. полях: имя, фамилия, отчество, телефон, email, как обращаться, название мета работы
         */
        query?: any,
        /**
         * Фильтр по id автомобиля
         */
        car?: any,
        /**
         * Режим отображения
         */
        viewMode?: 'alphabet' | 'list',
    }): CancelablePromise<{
        items?: Array<(ClientAlphabetListSchema | ClientListBoxSchema)>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/clients',
            query: {
                'state': state,
                'id': id,
                'query': query,
                'car': car,
                'view_mode': viewMode,
            },
        });
    }
    /**
     * Добавление клиента
     * Добавляет клиента
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static clientAdd({
        requestBody,
    }: {
        requestBody?: ClientAddSchema,
    }): CancelablePromise<{
        message?: string;
        clientId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Информация о клиенте
     * Возвращает данные указанного клиента
     * @returns any Клиент найден
     * @throws ApiError
     */
    public static clientGet({
        id,
    }: {
        /**
         * id клиента
         */
        id: any,
    }): CancelablePromise<{
        client?: ClientSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Обновление данных клиента
     * Обновляет данные указанного клиента
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static clientUpdate({
        id,
        requestBody,
    }: {
        /**
         * id клиента
         */
        id: any,
        requestBody?: ClientAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/client/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление клиента
     * Удаляет указанного клиента
     * @returns void
     * @throws ApiError
     */
    public static clientDelete({
        id,
    }: {
        /**
         * id клиента
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/client/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Устанавливает аватар клиента
     * Устанавливает аватар клиента
     * @returns any Аватар успешно сохранён
     * @throws ApiError
     */
    public static clientSetAvatar({
        id,
        formData,
    }: {
        /**
         * id клиента
         */
        id: any,
        formData: {
            /**
             * Файл изображения
             */
            avatar: Blob;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client/{id}/avatar',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Данные клиента
     * Вкладка Данные
     * @returns any Клиент найден
     * @throws ApiError
     */
    public static clientGetInfo({
        id,
    }: {
        /**
         * id клиента
         */
        id: any,
    }): CancelablePromise<{
        data?: ClientInfoSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{id}/info',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Автомобили клиента
     * Отфильтрованный список автомобилей клиента
     * @returns any Результат получен
     * @throws ApiError
     */
    public static clientGetCars({
        id,
        query,
    }: {
        /**
         * id клиента
         */
        id: any,
        /**
         * Поисковый запрос (поиск по марке, модели, гос. номеру или VIN)
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Список автомобилей клиента
         */
        items?: Array<ClientCarBasicSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{id}/cars',
            path: {
                'id': id,
            },
            query: {
                'query': query,
            },
        });
    }
    /**
     * Список заказ-нарядов клиента
     * Возвращает отфильтрованный список заказ-нарядов клиента
     * @returns any Результат получен
     * @throws ApiError
     */
    public static clientOrderIndex({
        masterArray,
        statusArray,
        date,
        state,
        query,
        page,
        limit,
    }: {
        /**
         * Мастер
         */
        masterArray?: any,
        /**
         * Статус
         */
        statusArray?: any,
        /**
         * Дата
         */
        date?: any,
        /**
         * Состояние (favorite | archived)
         */
        state?: any,
        /**
         * Поиск по след. полям: ФИО клиента, ФИО мастера, рег. номер автомобиля, номер заказа
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Заказ-наряды
         */
        orders?: Array<ClientOrderListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{id}/orders',
            query: {
                'master[]': masterArray,
                'status[]': statusArray,
                'date': date,
                'state': state,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Заметки клиента
     * Список заметок клиента по датам
     * @returns any Результат получен
     * @throws ApiError
     */
    public static clientGetNotes({
        id,
    }: {
        /**
         * id клиента
         */
        id: any,
    }): CancelablePromise<{
        /**
         * Список заметок клиента
         */
        notes?: Array<ClientNoteCollectionSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client/{id}/notes',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Добавление заметки
     * Добавляет заметку клиента
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static clientAddNote({
        id,
        requestBody,
    }: {
        /**
         * id клиента
         */
        id: any,
        requestBody?: ClientNoteAddSchema,
    }): CancelablePromise<{
        /**
         * Сообщение
         */
        message?: string;
        /**
         * id созданной заметки
         */
        noteId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client/{id}/note',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Редактирование заметки
     * Редактирует заметку клиента
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static clientUpdateNote({
        id,
        noteId,
        requestBody,
    }: {
        /**
         * id клиента
         */
        id: any,
        /**
         * id заметки клиента
         */
        noteId: any,
        requestBody?: ClientNoteAddSchema,
    }): CancelablePromise<{
        /**
         * Сообщение
         */
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/client/{id}/note/{note_id}',
            path: {
                'id': id,
                'note_id': noteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление заметки клиента
     * Удаляет указанную заметку клиента
     * @returns void
     * @throws ApiError
     */
    public static clientNoteDelete({
        id,
        noteId,
    }: {
        /**
         * id клиента
         */
        id: any,
        /**
         * id заметки клиента
         */
        noteId: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/client/{id}/note/{note_id}',
            path: {
                'id': id,
                'note_id': noteId,
            },
        });
    }
    /**
     * Список организаций клиентов
     * Возвращает отфильтрованный список организаций клиентов
     * @returns ClientWorkplaceCollectionSchema Результат получен
     * @throws ApiError
     */
    public static clientWorkplaceIndex({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: ClientWorkplaceFilterSchema,
    }): CancelablePromise<ClientWorkplaceCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/client-workplace',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Добавление организации клиента
     * Добавляет организацию в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static clientWorkplaceAdd({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        clientWorkplaceId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/client-workplace',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление организации клиента
     * Обновляет указанную организации клиента в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static clientWorkplaceUpdate({
        xRequestedWith,
        id,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id организации
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/client-workplace/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление организации клиента
     * Удаляет указанную организацию клиента из справочника
     * @returns void
     * @throws ApiError
     */
    public static clientWorkplaceDelete({
        xRequestedWith,
        id,
    }: {
        xRequestedWith: any,
        /**
         * id организации
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/client-workplace/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
        });
    }
    /**
     * Получение данных организации (автосервиса)
     * Возвращает данные автосервиса
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static companyGet({
        xRequestedWith,
    }: {
        xRequestedWith: any,
    }): CancelablePromise<{
        company?: CompanySchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Создание организации (автосервиса)
     * Первоначальное заполнение данных автосервиса
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static companyCreate({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: {
            name?: string;
            name_en?: string;
            legal_entity_id?: number;
            taxation_id?: number;
            /**
             * Поля организации (код=>значение)
             */
            fields?: {
                inn?: string;
                kpp?: string;
            };
        },
    }): CancelablePromise<{
        message?: string;
        companyId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/company',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Список полей организации
     * Возвращает отфильтрованный список полей организации
     * @returns CompanyFieldCollectionSchema Результат получен
     * @throws ApiError
     */
    public static companyFieldIndex({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: CompanyFieldFilterSchema,
    }): CancelablePromise<CompanyFieldCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/company-field',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Данные акта сверки
     * Получает данные акта сверки
     * @returns any Успешно
     * @throws ApiError
     */
    public static correctionGet({
        id,
    }: {
        /**
         * id акта сверки
         */
        id: any,
    }): CancelablePromise<{
        correction?: CorrectionSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/correction/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Обновление акта сверки
     * Обновляет акт сверки
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static correctionUpdate({
        id,
        requestBody,
    }: {
        /**
         * id акта сверки
         */
        id: any,
        requestBody?: CorrectionBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/correction/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Список актов сверки
     * Возвращает отфильтрованный список актов сверки
     * @returns any Результат получен
     * @throws ApiError
     */
    public static correctionIndex({
        id,
        dateFrom,
        dateTo,
        register,
        createdBy,
        query,
        page,
        limit,
    }: {
        /**
         * ID
         */
        id?: any,
        /**
         * Дата от
         */
        dateFrom?: any,
        /**
         * Дата до
         */
        dateTo?: any,
        /**
         * ID кассы
         */
        register?: any,
        /**
         * ID Ответственного
         */
        createdBy?: any,
        /**
         * Поиск по след. полям: ФИО ответственного, название кассы, комментарий, заявлено, фактически
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Акты сверки
         */
        items?: Array<CorrectionListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/corrections',
            query: {
                'id': id,
                'date[from]': dateFrom,
                'date[to]': dateTo,
                'register': register,
                'created_by': createdBy,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление акта сверки
     * Добавляет акт сверки
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static correctionAdd({
        requestBody,
    }: {
        requestBody?: CorrectionBasicSchema,
    }): CancelablePromise<{
        message?: string;
        correctionId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/correction',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Список бригад
     * Возвращает отфильтрованный список бригад
     * @returns CrewCollectionSchema Результат получен
     * @throws ApiError
     */
    public static crewIndex({
        id,
        name,
        location,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по локации
         */
        location?: any,
    }): CancelablePromise<CrewCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/crews',
            query: {
                'id': id,
                'name': name,
                'location': location,
            },
        });
    }
    /**
     * Добавление бригады
     * Добавляет бригаду
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static crewAdd({
        requestBody,
    }: {
        requestBody?: CrewSaveSchema,
    }): CancelablePromise<{
        message?: string;
        crewId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/crew',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Изменить бригаду
     * Изменяет данные бригады
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static crewUpdate({
        id,
        requestBody,
    }: {
        /**
         * id бригады
         */
        id: any,
        requestBody?: CrewSaveSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/crew/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Удаление бригады
     * Удаляет указанную бригаду
     * @returns void
     * @throws ApiError
     */
    public static crewDelete({
        id,
    }: {
        /**
         * id бригады
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/crew/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Привязка пользователя к бригаде
     * Привязывает пользователей к указанной бригаде
     * @returns any Успешно
     * @throws ApiError
     */
    public static crewUserAttach({
        id,
        requestBody,
    }: {
        /**
         * id бригады
         */
        id: any,
        requestBody?: ({
            /**
             * ID пользователя для привязки
             */
            user_id: number;
        } | {
            /**
             * ID пользователей для привязки
             */
            user_id: Array<number>;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/crew/{id}/user',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Исключение пользователя из бригады
     * Исключает пользователей из указанной бригады
     * @returns any Успешно
     * @throws ApiError
     */
    public static crewUserDetach({
        id,
        requestBody,
    }: {
        /**
         * id бригады
         */
        id: any,
        requestBody?: {
            /**
             * ID пользователей для привязки (один или несколько)
             */
            user_id: number;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/crew/{id}/user',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Добавление раздела
     * Добавляет раздел в документы
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static documentSectionAdd({
        requestBody,
    }: {
        requestBody?: DocumentSectionAddSchema,
    }): CancelablePromise<{
        message?: string;
        sectionId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/document/section',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Список документов раздела
     * Возвращает отфильтрованный список документов раздела
     * @returns DocumentListSchema Результат получен
     * @throws ApiError
     */
    public static documentSectionIndex({
        id,
        query,
    }: {
        /**
         * id раздела
         */
        id: any,
        /**
         * Ищет вхождение указанной подстроки в след. полях: название документа, название раздела
         */
        query?: any,
    }): CancelablePromise<DocumentListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/document/section/{id}',
            path: {
                'id': id,
            },
            query: {
                'query': query,
            },
        });
    }
    /**
     * Изменение раздела
     * Изменяет раздел документов
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static documentSectionUpdate({
        id,
        requestBody,
    }: {
        /**
         * id раздела
         */
        id: any,
        requestBody?: DocumentSectionAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/document/section/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Удаление раздела
     * Удаляет указанный раздел
     * @returns void
     * @throws ApiError
     */
    public static documentSectionDelete({
        id,
    }: {
        /**
         * id раздела
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/document/section/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список документов
     * Возвращает отфильтрованный список документов
     * @returns DocumentListSchema Результат получен
     * @throws ApiError
     */
    public static documentIndex({
        query,
    }: {
        /**
         * Ищет вхождение указанной подстроки в след. полях: название документа, название раздела
         */
        query?: any,
    }): CancelablePromise<DocumentListSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/documents',
            query: {
                'query': query,
            },
        });
    }
    /**
     * Добавление документа
     * Сохраняет документ в хранилище
     * @returns any Документ сохранён
     * @throws ApiError
     */
    public static documentElementAdd({
        formData,
    }: {
        formData: DocumentElementAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/document',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Получение документа
     * Скачивает указанный документ
     * @returns binary Успешно
     * @throws ApiError
     */
    public static documentElementDownload({
        id,
    }: {
        /**
         * id документа
         */
        id: any,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/document/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Изменение документа
     * Изменяет документ в хранилище
     * @returns any Документ изменён
     * @throws ApiError
     */
    public static documentElementUpdate({
        formData,
    }: {
        formData: DocumentElementAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/document/{id}',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Удаление документа
     * Удаляет указанный документ
     * @returns void
     * @throws ApiError
     */
    public static documentElementDelete({
        id,
    }: {
        /**
         * id документа
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/document/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Справочник типов двигателя
     * Возвращает отфильтрованный список типов двигателя
     * @returns EngineCollectionSchema Результат получен
     * @throws ApiError
     */
    public static engineIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<EngineCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/engines',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление тип двигателя
     * Добавляет тип двигателя в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static engineAdd({
        requestBody,
    }: {
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        engineId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/engine',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление типа двигателя
     * Обновляет указанный тип двигателя в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static engineUpdate({
        id,
        requestBody,
    }: {
        /**
         * id типа двигателя
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/engine/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление типа двигателя
     * Удаляет указанный тип двигателя из справочника
     * @returns void
     * @throws ApiError
     */
    public static engineDelete({
        id,
    }: {
        /**
         * id типа двигателя
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/engine/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Справочник типов топлива
     * Возвращает отфильтрованный список типов топлива
     * @returns FuelCollectionSchema Результат получен
     * @throws ApiError
     */
    public static fuelIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<FuelCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/fuels',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление типа топлива
     * Добавляет тип топлива в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static fuelAdd({
        requestBody,
    }: {
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        fuelId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/fuel',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление типа топлива
     * Обновляет указанный тип топлива в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static fuelUpdate({
        id,
        requestBody,
    }: {
        /**
         * id типа топлива
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/fuel/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление типа топлива
     * Удаляет указанный тип топлива из справочника
     * @returns void
     * @throws ApiError
     */
    public static fuelDelete({
        id,
    }: {
        /**
         * id типа топлива
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/fuel/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список выходных дней
     * Возвращает отфильтрованный список выходных дней
     * @returns HolidayCollectionSchema Результат получен
     * @throws ApiError
     */
    public static holidayIndex({
        requestBody,
    }: {
        requestBody?: HolidayFilterSchema,
    }): CancelablePromise<HolidayCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/holiday',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Добавление выходных дней
     * Добавляет выходные дни
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static holidayAdd({
        requestBody,
    }: {
        requestBody?: (HolidaySchema & {
            /**
             * Локации
             */
            location_id?: number;
        }),
    }): CancelablePromise<{
        message?: string;
        holidayId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/holiday',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление выходных дней
     * Обновляет указанные выходные дни
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static holidayUpdate({
        requestBody,
    }: {
        requestBody?: (HolidaySchema & {
            /**
             * Локации
             */
            location_id?: number;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/holiday/{id}',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление выходных дней
     * Удаляет указанные выходные дни из справочника
     * @returns void
     * @throws ApiError
     */
    public static holidayDelete({
        id,
    }: {
        /**
         * id выходных дней
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/holiday/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Справочник типов приходного документа
     * Возвращает список типов приходного документа
     * @returns IncomeDocumentTypeCollection Результат получен
     * @throws ApiError
     */
    public static incomeDocumentTypeIndex(): CancelablePromise<IncomeDocumentTypeCollection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/income/types',
        });
    }
    /**
     * Данные товарной накладной
     * Данные товарной накладной
     * @returns any Успешно
     * @throws ApiError
     */
    public static invoiceGet({
        id,
    }: {
        /**
         * id накладной
         */
        id: any,
    }): CancelablePromise<{
        invoice?: InvoiceSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение товарной накладной
     * Изменяет товарную накладную
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static invoiceUpdate({
        id,
        requestBody,
    }: {
        /**
         * id накладной
         */
        id: any,
        requestBody?: InvoiceBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/invoice/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление товарной накладной
     * Удаляет товарную накладную
     * @returns void
     * @throws ApiError
     */
    public static invoiceDelete({
        id,
    }: {
        /**
         * id накладной
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/invoice/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список товарных накладных
     * Возвращает отфильтрованный список товарных накладных
     * @returns any Результат получен
     * @throws ApiError
     */
    public static invoiceIndex({
        employee,
        date,
        isPaid,
        query,
        page,
        limit,
    }: {
        /**
         * ID ответственного
         */
        employee?: any,
        /**
         * Дата
         */
        date?: any,
        /**
         * Оплачен
         */
        isPaid?: any,
        /**
         * Поиск по след. полям: номер, фамилия ответственного, контрагент
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Товарные накладные
         */
        items?: Array<InvoiceListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoices',
            query: {
                'employee': employee,
                'date': date,
                'is_paid': isPaid,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление товарной накладной
     * Добавляет товарную накладную
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static invoiceAdd({
        requestBody,
    }: {
        requestBody?: InvoiceBasicSchema,
    }): CancelablePromise<{
        message?: string;
        invoiceId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/invoice',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Позиции товарной накладной
     * Возвращает отфильтрованный список позиций товарной накладной
     * @returns any Результат получен
     * @throws ApiError
     */
    public static invoiceItemIndex({
        id,
    }: {
        /**
         * id накладной
         */
        id: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Позиции товарной накладной
         */
        items?: Array<InvoiceItemListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/invoice/{id}/items',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Удаление позиции товарной накладной
     * Удаляет позицию товарной накладной
     * @returns void
     * @throws ApiError
     */
    public static invoiceItemDelete({
        id,
    }: {
        /**
         * id позиции в накладной
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/invoice/item/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список типов юридической регистрации
     * Возвращает отфильтрованный список типов юридической регистрации
     * @returns LegalEntityCollectionSchema Результат получен
     * @throws ApiError
     */
    public static legalEntityIndex({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: LegalEntityFilterSchema,
    }): CancelablePromise<LegalEntityCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/legal-entity',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Данные типа юридической регистрации
     * Возвращает информацию об указанном типе юридической регистрации
     * @returns any Данные получены
     * @throws ApiError
     */
    public static legalEntityGet({
        xRequestedWith,
        id,
    }: {
        xRequestedWith: any,
        /**
         * id типа юридической регистрации
         */
        id: any,
    }): CancelablePromise<{
        legal_entity?: LegalEntitySchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/legal-entity/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Список локаций
     * Возвращает отфильтрованный список локаций
     * @returns LocationCollectionSchema Результат получен
     * @throws ApiError
     */
    public static locationIndex({
        id,
        name,
        email,
        phone,
        query,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по email (поиск подстроки)
         */
        email?: any,
        /**
         * Фильтр по номеру телефона
         */
        phone?: any,
        /**
         * Ищет вхождение указанной подстроки в след. полях: адрес, название, телефон, email, описание
         */
        query?: any,
    }): CancelablePromise<LocationCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/locations',
            query: {
                'id': id,
                'name': name,
                'email': email,
                'phone': phone,
                'query': query,
            },
        });
    }
    /**
     * Добавление локации
     * Добавляет локацию
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static locationAdd({
        requestBody,
    }: {
        requestBody?: LocationBasicSchema,
    }): CancelablePromise<{
        message?: string;
        locationId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление локации
     * Обновляет локацию с указанным id
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static locationUpdate({
        id,
        requestBody,
    }: {
        /**
         * id локации
         */
        id: any,
        requestBody?: LocationBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/location/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление локации
     * Удаляет указанную локацию
     * @returns void
     * @throws ApiError
     */
    public static locationDelete({
        id,
    }: {
        /**
         * id локации
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/location/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Общая информация
     * Общая информация
     * @returns any Результат получен
     * @throws ApiError
     */
    public static locationPageSettings({
        id,
    }: {
        /**
         * id локации
         */
        id: any,
    }): CancelablePromise<{
        data?: LocationSettingsSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/settings',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Рабочие места
     * Рабочие места
     * @returns any Результат получен
     * @throws ApiError
     */
    public static locationPageWorkPlaces({
        id,
    }: {
        /**
         * id локации
         */
        id: any,
    }): CancelablePromise<{
        data?: LocationWorkPlacesSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/workplaces',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Склады
     * Склады
     * @returns any Результат получен
     * @throws ApiError
     */
    public static locationPageStorages({
        id,
    }: {
        /**
         * id локации
         */
        id: any,
    }): CancelablePromise<{
        data?: LocationStoragesSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/storages',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Бригады
     * Бригады
     * @returns any Результат получен
     * @throws ApiError
     */
    public static locationPageCrews({
        id,
    }: {
        /**
         * id локации
         */
        id: any,
    }): CancelablePromise<{
        data?: LocationCrewsSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/crews',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Рабочий график
     * Рабочий график
     * @returns any Результат получен
     * @throws ApiError
     */
    public static locationPageShiftCalendar({
        id,
    }: {
        /**
         * id локации
         */
        id: any,
    }): CancelablePromise<{
        data?: LocationShiftCalendarSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/location/{id}/shift-calendar',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Календарь смен
     * Рассчитывает календарь смен по параметрам
     * @returns any Результат получен
     * @throws ApiError
     */
    public static locationRenderShiftCalendar({
        id,
        requestBody,
    }: {
        /**
         * id локации
         */
        id: any,
        requestBody?: ShiftCalendarRenderSchema,
    }): CancelablePromise<{
        /**
         * Календарь смен
         */
        calendar?: Array<ShiftCalendarRenderResultSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/location/{id}/shift-calendar',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Список комментариев заказ-наряда
     * Возвращает список комментариев заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderCommentIndex({
        id,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
    }): CancelablePromise<{
        comments?: Array<OrderCommentCollectionSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}/comments',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Добавление комментария заказ-наряда
     * Добавляет комментарий заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderCommentAdd({
        id,
        formData,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        formData: OrderCommentAddSchema,
    }): CancelablePromise<{
        message?: string;
        commentId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/comment',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Обновление комментария заказ-наряда
     * Обновляет комментарий заказ-наряда
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static orderCommentUpdate({
        id,
        formData,
    }: {
        /**
         * id комментария заказ-наряда
         */
        id: any,
        formData: (OrderCommentAddSchema & {
            /**
             * Массив существующих файлов, которые необходимо сохранить
             */
            keep?: Array<number>;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/comment/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление комментария заказ-наряда
     * Удаляет комментарий заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderCommentDelete({
        id,
    }: {
        /**
         * id комментария
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/comment/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список заказ-нарядов
     * Возвращает отфильтрованный список заказ-нарядов
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderIndex({
        masterArray,
        statusArray,
        statusExcludeArray,
        date,
        state,
        query,
        viewMode,
        page,
        limit,
    }: {
        /**
         * Мастер
         */
        masterArray?: any,
        /**
         * Статус
         */
        statusArray?: any,
        /**
         * Исключить статус
         */
        statusExcludeArray?: any,
        /**
         * Дата
         */
        date?: any,
        /**
         * Состояние (favorite | archived)
         */
        state?: any,
        /**
         * Поиск по след. полям: ФИО клиента, ФИО мастера, рег. номер автомобиля, номер заказа
         */
        query?: any,
        /**
         * Режим отображения
         */
        viewMode?: 'columns' | 'kanban' | 'list',
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Заказ-наряды
         */
        items?: Array<(OrderListViewSchema | OrderKanbanSchema | OrderListBoxSchema)>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/orders',
            query: {
                'master[]': masterArray,
                'status[]': statusArray,
                'statusExclude[]': statusExcludeArray,
                'date': date,
                'state': state,
                'query': query,
                'view_mode': viewMode,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Данные заказ-наряда
     * Данные заказ-наряда
     * @returns any Успешно
     * @throws ApiError
     */
    public static orderGet({
        id,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
    }): CancelablePromise<{
        order?: OrderSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Обновление заказ-наряда
     * Обновляет заказ-наряд
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static orderUpdate({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: OrderBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Добавление заказ-наряда
     * Добавляет заказ-наряд
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderAdd({
        requestBody,
    }: {
        requestBody?: OrderBasicSchema,
    }): CancelablePromise<{
        message?: string;
        orderId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Фильтры списка заказ-нарядов
     * Возвращает доступные фильтры для списка заказ-нарядов
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderFacets(): CancelablePromise<{
        /**
         * Фильтры
         */
        facets?: Array<(FilterDateField | FilterSelectField)>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/facets',
        });
    }
    /**
     * Блокировка заказ-наряда
     * Запрещает редактирование заказ-наряда
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderLock({
        id,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/{id}/lock',
            path: {
                'id': id,
            },
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Разблокировка заказ-наряда
     * Снимает запрет на редактирование заказ-наряда
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderUnlock({
        id,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/{id}/unlock',
            path: {
                'id': id,
            },
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Данные дефектовки
     * Данные дефектовки заказ-наряда
     * @returns any Успешно
     * @throws ApiError
     */
    public static orderDefectGet({
        id,
    }: {
        /**
         * id дефектовки
         */
        id: any,
    }): CancelablePromise<{
        defect?: OrderDefectSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение дефектовки в заказ-наряде
     * Изменяет дефектовку в заказ-наряде
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderDefectUpdate({
        id,
        requestBody,
    }: {
        /**
         * id дефектовки в заказ-наряде
         */
        id: any,
        requestBody?: OrderDefectBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/defect/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление дефектовки из заказ-наряда
     * Удаляет дефектовку из заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderDefectDelete({
        id,
    }: {
        /**
         * id дефектовки в заказ-наряде
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/defect/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Дефектовка заказ-наряда
     * Возвращает отфильтрованный список дефектовки заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderDefectIndex({
        id,
        name,
        query,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию
         */
        name?: any,
        /**
         * Поиск по след. полям: название
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Дефектовка
         */
        items?: Array<OrderDefectListViewSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}/defects',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавление дефектовки в заказ-наряд
     * Добавляет дефектовку в заказ-наряд
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderDefectAdd({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: OrderDefectBasicSchema,
    }): CancelablePromise<{
        message?: string;
        defectId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/defect',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Данные работы дефектовки заказ-наряда
     * Данные работы дефектовки заказ-наряда
     * @returns any Успешно
     * @throws ApiError
     */
    public static orderDefectJobGet({
        id,
    }: {
        /**
         * id работы
         */
        id: any,
    }): CancelablePromise<{
        job?: OrderDefectJobSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect/job/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение работы в дефектовке заказ-наряда
     * Изменяет работу в дефектовке заказ-наряда
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderDefectJobUpdate({
        id,
        requestBody,
    }: {
        /**
         * id работы в дефектовке заказ-наряда
         */
        id: any,
        requestBody?: OrderDefectJobBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/defect/job/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление работы из дефектовки заказ-наряда
     * Удаляет работу из дефектовки заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderDefectJobDelete({
        id,
    }: {
        /**
         * id работы в дефектовке заказ-наряде
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/defect/job/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Работы дефектовки заказ-наряда
     * Возвращает отфильтрованный список работ дефектовки заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderDefectJobIndex({
        id,
        name,
        query,
    }: {
        /**
         * id дефектовки заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию работы
         */
        name?: any,
        /**
         * Поиск по след. полям: название работы
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Работы
         */
        items?: Array<OrderDefectJobListViewSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect/{id}/jobs',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавление работы в дефектовку заказ-наряда
     * Добавляет запчасть в дефектовку заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderDefectJobAdd({
        id,
        requestBody,
    }: {
        /**
         * id дефектовки заказ-наряда
         */
        id: any,
        requestBody?: OrderDefectJobBasicSchema,
    }): CancelablePromise<{
        message?: string;
        jobId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/defect/{id}/job',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Данные запчасти дефектовки заказ-наряда
     * Данные запчасти дефектовки заказ-наряда
     * @returns any Успешно
     * @throws ApiError
     */
    public static orderDefectPartGet({
        id,
    }: {
        /**
         * id запчасти
         */
        id: any,
    }): CancelablePromise<{
        part?: OrderDefectPartSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect/part/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение запчасти в дефектовке заказ-наряда
     * Изменяет запчасть в дефектовке заказ-наряда
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderDefectPartUpdate({
        id,
        requestBody,
    }: {
        /**
         * id запчасти в дефектовке заказ-наряда
         */
        id: any,
        requestBody?: OrderDefectPartBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/defect/part/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление запчасти из дефектовки заказ-наряда
     * Удаляет запчасть из дефектовки заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderDefectPartDelete({
        id,
    }: {
        /**
         * id запчасти в дефектовке заказ-наряде
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/defect/part/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Запчасти дефектовки заказ-наряда
     * Возвращает отфильтрованный список запчастей дефектовки заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderDefectPartIndex({
        id,
        name,
        query,
    }: {
        /**
         * id дефектовки заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию работы
         */
        name?: any,
        /**
         * Поиск по след. полям: название запчасти
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Запчасти
         */
        items?: Array<OrderDefectPartListViewSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect/{id}/parts',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавление запчасти в дефектовку заказ-наряда
     * Добавляет запчасть в дефектовку заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderDefectPartAdd({
        id,
        requestBody,
    }: {
        /**
         * id дефектовки заказ-наряда
         */
        id: any,
        requestBody?: OrderDefectPartBasicSchema,
    }): CancelablePromise<{
        message?: string;
        partId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/defect/{id}/part',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Список статусов дефекта в заказ-наряда
     * Возвращает список статусов дефекта заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderDefectPriorityIndex(): CancelablePromise<{
        items?: Array<OrderDefectPrioritySchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect-priorities',
        });
    }
    /**
     * Добавление статуса дефекта заказ-наряда
     * Добавляет статус дефекта заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderDefectPriorityAdd({
        requestBody,
    }: {
        requestBody?: OrderDefectPriorityBasicSchema,
    }): CancelablePromise<{
        message?: string;
        priorityId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/defect-priority',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Обновление статуса дефекта заказ-наряда
     * Обновляет данные указанного статуса дефекта
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderDefectPriorityUpdate({
        id,
        requestBody,
    }: {
        /**
         * id статуса
         */
        id: any,
        requestBody?: OrderDefectPriorityBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/defect-priority/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление статуса дефекта заказ-наряда
     * Удаляет статус дефекта заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderDefectPriorityDelete({
        id,
    }: {
        /**
         * id статуса
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/defect-priority/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список статусов процесса для дефекта в заказ-наряда
     * Возвращает список статусов процесса для дефекта заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderDefectStatusIndex(): CancelablePromise<{
        items?: Array<OrderDefectStatusSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/defect-statuses',
        });
    }
    /**
     * Добавление статуса процесса для дефекта заказ-наряда
     * Добавляет статус процесса для дефекта заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderDefectStatusAdd({
        requestBody,
    }: {
        requestBody?: OrderDefectStatusBasicSchema,
    }): CancelablePromise<{
        message?: string;
        statusId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/defect-status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Обновление статуса процесса для дефекта заказ-наряда
     * Обновляет данные указанного статуса процесса для дефекта
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderDefectStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * id статуса
         */
        id: any,
        requestBody?: OrderDefectStatusBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/defect-status/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление статуса процесса для дефекта заказ-наряда
     * Удаляет статус процесса для дефекта заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderDefectStatusDelete({
        id,
    }: {
        /**
         * id статуса
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/defect-status/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Данные работы заказ-наряда
     * Данные работы заказ-наряда
     * @returns any Успешно
     * @throws ApiError
     */
    public static orderJobGet({
        id,
    }: {
        /**
         * id работы
         */
        id: any,
    }): CancelablePromise<{
        job?: OrderJobSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/job/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение работы в заказ-наряде
     * Изменяет работу в заказ-наряде
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderJobUpdate({
        id,
        requestBody,
    }: {
        /**
         * id работы в заказ-наряде
         */
        id: any,
        requestBody?: OrderJobBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/job/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление работы из заказ-наряда
     * Удаляет работу из заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderJobDelete({
        id,
    }: {
        /**
         * id работы в заказ-наряде
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/job/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Работы заказ-наряда
     * Возвращает отфильтрованный список работ заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderJobIndex({
        id,
        name,
        query,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию работы
         */
        name?: any,
        /**
         * Поиск по след. полям: название работы
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Работы
         */
        items?: Array<OrderJobListViewSchema>;
        summary?: OrderJobSummarySchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}/jobs',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавление работы в заказ-наряд
     * Добавляет работу в заказ-наряд
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderJobAdd({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: OrderJobBasicSchema,
    }): CancelablePromise<{
        message?: string;
        itemId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/job',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Данные запчасти заказ-наряда
     * Данные запчасти заказ-наряда
     * @returns any Успешно
     * @throws ApiError
     */
    public static orderPartGet({
        id,
    }: {
        /**
         * id запчасти
         */
        id: any,
    }): CancelablePromise<{
        part?: OrderPartSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/part/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение запчасти в заказ-наряде
     * Изменяет запчасть в заказ-наряде
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderPartUpdate({
        id,
        requestBody,
    }: {
        /**
         * id запчасти в заказ-наряде
         */
        id: any,
        requestBody?: OrderPartBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/part/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление запчасти из заказ-наряда
     * Удаляет запчасть из заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderPartDelete({
        id,
    }: {
        /**
         * id запчасти в заказ-наряде
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/part/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Запчасти заказ-наряда
     * Возвращает отфильтрованный список запчастей заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderPartIndex({
        id,
        name,
        query,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию запчасти
         */
        name?: any,
        /**
         * Поиск по след. полям: название запчасти
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Запчасти
         */
        items?: Array<OrderPartListViewSchema>;
        summary?: OrderPartSummarySchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}/parts',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавить из дефектовки
     * Добавляет выбранные запчасти из дефектовки в заказ-наряд
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderPartFill({
        id,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
    }): CancelablePromise<{
        /**
         * Сообщение
         */
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/parts',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Добавление запчасти в заказ-наряд
     * Добавляет запчасть в заказ-наряд
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderPartAdd({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: OrderPartBasicSchema,
    }): CancelablePromise<{
        message?: string;
        partId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/part',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление нескольких запчастей в заказ-наряде
     * Удаляет запчасти в заказ-наряде
     * @returns void
     * @throws ApiError
     */
    public static orderPartDeleteMany({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: ({
            /**
             * ID запчастей
             */
            order_part_id: number;
        } | {
            /**
             * ID запчастей
             */
            order_part_id: Array<number>;
        }),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/parts/delete',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Проводка запчасти заказ-наряда
     * Проводит запчасть заказ-наряда
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderPartSpend({
        id,
    }: {
        /**
         * id запчасти в заказ-наряде
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/part/{id}/spend',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Проводка нескольких запчастей в заказ-наряде
     * Проводит запчасти в заказ-наряде
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderPartSpendMany({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: ({
            /**
             * ID запчастей
             */
            order_part_id: number;
        } | {
            /**
             * ID запчастей
             */
            order_part_id: Array<number>;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/parts/spend',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Возврат запчасти заказ-наряда
     * Возвращает запчасть заказ-наряда
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderPartReturn({
        id,
    }: {
        /**
         * id запчасти в заказ-наряде
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/order/part/{id}/return',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Возврат нескольких запчастей в заказ-наряде
     * Возвращает запчасти в заказ-наряде
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderPartReturnMany({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: ({
            /**
             * ID запчастей
             */
            order_part_id: number;
        } | {
            /**
             * ID запчастей
             */
            order_part_id: Array<number>;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/parts/return',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Рекомендации заказ-наряда
     * Возвращает отфильтрованный список рекомендаций заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderRecommendIndex({
        id,
        name,
        query,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию
         */
        name?: any,
        /**
         * Поиск по след. полям: название, фамилия мастера
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Рекомендации
         */
        items?: Array<OrderRecommendListViewSchema>;
        summary?: OrderRecommendSummarySchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}/recommends',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавление рекомендации в заказ-наряд
     * Добавляет рекомендацию в заказ-наряд
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderRecommendAdd({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: OrderRecommendBasicSchema,
    }): CancelablePromise<{
        message?: string;
        recommendId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/recommend',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Изменение рекомендации в заказ-наряде
     * Изменяет рекомендацию в заказ-наряде
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderRecommendUpdate({
        id,
        requestBody,
    }: {
        /**
         * id рекомендации в заказ-наряде
         */
        id: any,
        requestBody?: OrderRecommendBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/recommend/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление рекомендации из заказ-наряда
     * Удаляет рекомендацию из заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderRecommendDelete({
        id,
    }: {
        /**
         * id рекомендации в заказ-наряде
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/recommend/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список статусов заказ-наряда
     * Возвращает список статусов заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderStatusIndex(): CancelablePromise<{
        statuses?: Array<OrderStatusSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/statuses',
        });
    }
    /**
     * Добавление статуса заказ-наряда
     * Добавляет статус заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderStatusAdd({
        requestBody,
    }: {
        requestBody?: OrderStatusAddSchema,
    }): CancelablePromise<{
        message?: string;
        statusId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/status',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Обновление статуса заказ-наряда
     * Обновляет данные указанного статуса
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static orderStatusUpdate({
        id,
        requestBody,
    }: {
        /**
         * id статуса
         */
        id: any,
        requestBody?: OrderStatusAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/status/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление статуса заказ-наряда
     * Удаляет статус заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderStatusDelete({
        id,
    }: {
        /**
         * id статуса
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/status/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Рабочий лист заказ-наряда
     * Возвращает отфильтрованный рабочий лист заказ-наряда
     * @returns any Результат получен
     * @throws ApiError
     */
    public static orderWorkListIndex({
        id,
        name,
        query,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        /**
         * Поиск по названию работы
         */
        name?: any,
        /**
         * Поиск по след. полям: название работы
         */
        query?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Рабочий лист
         */
        items?: Array<OrderWorkListListViewSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/order/{id}/work-list',
            path: {
                'id': id,
            },
            query: {
                'name': name,
                'query': query,
            },
        });
    }
    /**
     * Добавление элемента рабочего листа заказ-наряда
     * Добавляет элемент рабочего листа заказ-наряда
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static orderWorkListAdd({
        id,
        requestBody,
    }: {
        /**
         * id заказ-наряда
         */
        id: any,
        requestBody?: OrderWorkListAddSchema,
    }): CancelablePromise<{
        message?: string;
        itemId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/order/{id}/work-list',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Изменение элемента рабочего листа заказ-наряда
     * Изменяет элемент рабочего листа заказ-наряда
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static orderWorkListUpdate({
        id,
        requestBody,
    }: {
        /**
         * id элемента рабочего листа заказ-наряда
         */
        id: any,
        requestBody?: OrderWorkListAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/order/work-list/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление элемента рабочего листа заказ-наряда
     * Удаляет элемент рабочего листа заказ-наряда
     * @returns void
     * @throws ApiError
     */
    public static orderWorkListDelete({
        id,
    }: {
        /**
         * id элемента рабочего листа заказ-наряда
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/order/work-list/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Справочник брендов запчастей
     * Возвращает отфильтрованный список брендов запчастей
     * @returns PartBrandCollectionSchema Результат получен
     * @throws ApiError
     */
    public static partBrandIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<PartBrandCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part-brands',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление бренда запчастей
     * Добавляет бренд запчастей в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static partBrandAdd({
        requestBody,
    }: {
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        brandId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/part-brand',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление бренда запчастей
     * Обновляет указанный бренд в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static partBrandUpdate({
        id,
        requestBody,
    }: {
        /**
         * id бренда
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/part-brand/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление бренда запчастей
     * Удаляет указанный бренд из справочника
     * @returns void
     * @throws ApiError
     */
    public static partBrandDelete({
        id,
    }: {
        /**
         * id бренда
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part-brand/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Данные запчасти
     * Данные запчасти
     * @returns any Успешно
     * @throws ApiError
     */
    public static partGet({
        id,
    }: {
        /**
         * id запчасти
         */
        id: any,
    }): CancelablePromise<{
        part?: PartSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Обновление запчасти
     * Обновляет указанную запчасть
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static partUpdate({
        id,
        requestBody,
    }: {
        /**
         * id запчасти
         */
        id: any,
        requestBody?: PartBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/part/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление запчасти
     * Удаляет указанную запчасть
     * @returns void
     * @throws ApiError
     */
    public static partDelete({
        id,
    }: {
        /**
         * id запчасти
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список запчастей
     * Возвращает отфильтрованный список запчастей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static partIndex({
        name,
        sku,
        article,
        brand,
        rack,
        isFavorite,
        isArchive,
        hasMinQuantity,
        query,
        viewMode,
        page,
        limit,
    }: {
        /**
         * Название
         */
        name?: any,
        /**
         * Каталожный номер
         */
        sku?: any,
        /**
         * Внутренний артикул
         */
        article?: any,
        /**
         * ID бренда
         */
        brand?: any,
        /**
         * ID места хранения
         */
        rack?: any,
        /**
         * Избранное
         */
        isFavorite?: any,
        /**
         * Архивный
         */
        isArchive?: any,
        /**
         * Указано минимальное количество
         */
        hasMinQuantity?: any,
        /**
         * Поиск по след. полям: название, кат. номер, внутр. аритикул, название бренда
         */
        query?: any,
        /**
         * Режим отображения
         */
        viewMode?: 'columns' | 'list',
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Запчасти
         */
        items?: Array<(PartListBoxSchema | PartListViewSchema)>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/parts',
            query: {
                'name': name,
                'sku': sku,
                'article': article,
                'brand': brand,
                'rack': rack,
                'is_favorite': isFavorite,
                'is_archive': isArchive,
                'has_min_quantity': hasMinQuantity,
                'query': query,
                'view_mode': viewMode,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление запчасти
     * Добавляет запчасть
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static partAdd({
        requestBody,
    }: {
        requestBody?: PartBasicSchema,
    }): CancelablePromise<{
        message?: string;
        partId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/part',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Устанавливает изображение запчасти
     * Устанавливает изображение запчасти
     * @returns any Изображение успешно сохранено
     * @throws ApiError
     */
    public static partSetPicture({
        id,
        formData,
    }: {
        /**
         * id запчасти
         */
        id: any,
        formData: {
            /**
             * Файл изображения
             */
            picture: Blob;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/part/{id}/picture',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Данные поступления запчастей
     * Данные поступления запчастей
     * @returns any Успешно
     * @throws ApiError
     */
    public static incomeGet({
        id,
    }: {
        /**
         * id поступления
         */
        id: any,
    }): CancelablePromise<{
        income?: IncomeSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/income/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение поступления запчастей
     * Изменяет поступление запчастей
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static incomeUpdate({
        id,
        requestBody,
    }: {
        /**
         * id поступления
         */
        id: any,
        requestBody?: IncomeBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/part/income/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление поступления запчастей
     * Удаляет поступление запчастей
     * @returns void
     * @throws ApiError
     */
    public static incomeDelete({
        id,
    }: {
        /**
         * id поступления
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part/income/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список поступлений запчастей
     * Возвращает отфильтрованный список поступлений запчастей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static incomeIndex({
        employee,
        dateFrom,
        dateTo,
        isPaid,
        isDelivered,
        query,
        page,
        limit,
    }: {
        /**
         * ID ответственного
         */
        employee?: any,
        /**
         * Дата от
         */
        dateFrom?: any,
        /**
         * Дата до
         */
        dateTo?: any,
        /**
         * Оплачен
         */
        isPaid?: any,
        /**
         * Доставлен
         */
        isDelivered?: any,
        /**
         * Поиск по след. полям: номер, фамилия ответственного, ИНН продавца, продавец
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Товарные накладные
         */
        items?: Array<IncomeListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/incomes',
            query: {
                'employee': employee,
                'date[from]': dateFrom,
                'date[to]': dateTo,
                'is_paid': isPaid,
                'is_delivered': isDelivered,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление поступления запчастей
     * Добавляет поступление запчастей
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static incomeAdd({
        requestBody,
    }: {
        requestBody?: IncomeBasicSchema,
    }): CancelablePromise<{
        message?: string;
        incomeId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/part/income',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Позиции поступления запчастей
     * Возвращает отфильтрованный список позиций поступления запчастей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static incomeItemIndex({
        id,
    }: {
        /**
         * id поступления
         */
        id: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Позиции поступления запчастей
         */
        items?: Array<IncomeItemListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/income/{id}/items',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Удаление позиции поступления запчастей
     * Удаляет позицию поступления запчастей
     * @returns void
     * @throws ApiError
     */
    public static incomeItemDelete({
        id,
    }: {
        /**
         * id позиции
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part/income/item/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Данные заказа запчастей
     * Данные заказа запчастей
     * @returns any Успешно
     * @throws ApiError
     */
    public static partOrderGet({
        id,
    }: {
        /**
         * id заказа
         */
        id: any,
    }): CancelablePromise<{
        partOrder?: PartOrderSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/order/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение заказа запчастей
     * Изменяет заказ запчастей
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static partOrderUpdate({
        id,
        requestBody,
    }: {
        /**
         * id заказа
         */
        id: any,
        requestBody?: PartOrderBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/part/order/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление заказа запчастей
     * Удаляет заказ запчастей
     * @returns void
     * @throws ApiError
     */
    public static partOrderDelete({
        id,
    }: {
        /**
         * id заказа
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part/order/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список заказов запчастей
     * Возвращает отфильтрованный список заказов запчастей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static partOrderIndex({
        employee,
        dateFrom,
        dateTo,
        isPaid,
        query,
        page,
        limit,
    }: {
        /**
         * ID ответственного
         */
        employee?: any,
        /**
         * Дата от
         */
        dateFrom?: any,
        /**
         * Дата до
         */
        dateTo?: any,
        /**
         * Оплачен
         */
        isPaid?: any,
        /**
         * Поиск по след. полям: номер, фамилия ответственного, ИНН продавца, продавец
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Товарные накладные
         */
        items?: Array<PartOrderListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/orders',
            query: {
                'employee': employee,
                'date[from]': dateFrom,
                'date[to]': dateTo,
                'is_paid': isPaid,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление заказа запчастей
     * Добавляет заказ запчастей
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static partOrderAdd({
        requestBody,
    }: {
        requestBody?: PartOrderBasicSchema,
    }): CancelablePromise<{
        message?: string;
        partOrderId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/part/order',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Позиции заказа запчастей
     * Возвращает отфильтрованный список позиций заказа запчастей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static partOrderItemIndex({
        id,
    }: {
        /**
         * id заказа
         */
        id: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Позиции поступления запчастей
         */
        items?: Array<PartOrderItemListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/order/{id}/items',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Удаление позиции заказа запчастей
     * Удаляет позицию заказа запчастей
     * @returns void
     * @throws ApiError
     */
    public static partOrderItemDelete({
        id,
    }: {
        /**
         * id позиции
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part/order/item/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Данные резера запчастей
     * Данные резера запчастей
     * @returns any Успешно
     * @throws ApiError
     */
    public static partReserveGet({
        id,
    }: {
        /**
         * id резерва
         */
        id: any,
    }): CancelablePromise<{
        reserve?: PartReserveSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/reserve/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Отмена резерва запчастей
     * Отменяет резерв запчастей
     * @returns void
     * @throws ApiError
     */
    public static partReserveDelete({
        id,
    }: {
        /**
         * id резерва
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/part/reserve/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список резервов запчастей
     * Возвращает отфильтрованный список резервов запчастей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static partReserveIndex({
        employee,
        dateFrom,
        dateTo,
        query,
        page,
        limit,
    }: {
        /**
         * ID ответственного
         */
        employee?: any,
        /**
         * Дата от
         */
        dateFrom?: any,
        /**
         * Дата до
         */
        dateTo?: any,
        /**
         * Поиск по след. полям: номер, фамилия ответственного, контрагент
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Резервы запчастей
         */
        items?: Array<PartReserveListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/part/reserves',
            query: {
                'employee': employee,
                'date[from]': dateFrom,
                'date[to]': dateTo,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление резерва запчастей
     * Добавляет резерв запчастей
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static partReserveAdd({
        requestBody,
    }: {
        requestBody?: PartReserveBasicSchema,
    }): CancelablePromise<{
        message?: string;
        reserveId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/part/reserve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Данные элемента (резерва)
     * Возвращает данные указанного элемента (резерва)
     * @returns any Элемент найден
     * @throws ApiError
     */
    public static plannerItemGet({
        id,
    }: {
        /**
         * id элемента (резерва)
         */
        id: any,
    }): CancelablePromise<(PlannerItemDataSchema | PlannerReserveDataSchema | PlannerFirstDataSchema)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/planner/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Удаление элемента планировщика
     * Удаляет указанный элемент (или резерв) из планировщика
     * @returns void
     * @throws ApiError
     */
    public static plannerItemDelete({
        id,
    }: {
        /**
         * id элемента или резерва
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/planner/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Получение элементов планировщика
     * Получает элементы планировщика
     * @returns any Результат получен
     * @throws ApiError
     */
    public static plannerItemIndex({
        date,
    }: {
        /**
         * Фильтр по дате (за указанный день)
         */
        date: any,
    }): CancelablePromise<{
        /**
         * Локации
         */
        locations?: Array<PlannerLocationSchema>;
        /**
         * Напоминания
         */
        reminders?: Array<PlannerNotificationSchema>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/planner',
            query: {
                'date': date,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Добавление элемента планировщика
     * Добавляет элемент планировщика
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static plannerItemAdd({
        requestBody,
    }: {
        requestBody?: PlannerItemAddSchema,
    }): CancelablePromise<{
        message?: string;
        itemId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/planner/item',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Резерв поста
     * Резерв поста
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static plannerReserveAdd({
        requestBody,
    }: {
        requestBody?: PlannerReserveAddSchema,
    }): CancelablePromise<{
        message?: string;
        itemId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/planner/reserve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Добавление элемента планировщика (первый раз)
     * Добавляет элемент планировщика (первый раз)
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static plannerFirstAdd({
        requestBody,
    }: {
        requestBody?: PlannerFirstAddSchema,
    }): CancelablePromise<{
        message?: string;
        itemId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/planner/first',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Изменить резерв поста
     * Изменяет данные резерва поста
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static plannerReserveUpdate({
        id,
        requestBody,
    }: {
        /**
         * id резерва
         */
        id: any,
        requestBody?: PlannerReserveAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/planner/reserve/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение элемента планировщика
     * Изменяет элемент планировщика
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static plannerItemUpdate({
        id,
        requestBody,
    }: {
        /**
         * id элемента
         */
        id: any,
        requestBody?: PlannerItemAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/planner/item/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Изменение элемента планировщика (тип = первый раз)
     * Изменяет элемент планировщика (тип = первый раз)
     * @returns any Успешное изменение
     * @throws ApiError
     */
    public static plannerFirstUpdate({
        id,
        requestBody,
    }: {
        /**
         * id элемента
         */
        id: any,
        requestBody?: PlannerFirstAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/planner/first/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Данные напоминания в планировщике
     * Данные напоминания в планировщике
     * @returns any Успешно
     * @throws ApiError
     */
    public static plannerNotificationGet({
        id,
    }: {
        /**
         * id напоминания
         */
        id: any,
    }): CancelablePromise<{
        notification?: PlannerNotificationSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/planner-notification/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Редактирование напоминания в планировщике
     * Изменяет напоминание в планировщике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static plannerNotificationUpdate({
        id,
        requestBody,
    }: {
        /**
         * id напоминания
         */
        id: any,
        requestBody?: PlannerNotificationBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/planner-notification/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Удаление напоминания из планировщика
     * Удаляет указанное напоминание из планировщика
     * @returns void
     * @throws ApiError
     */
    public static plannerNotificationDelete({
        id,
    }: {
        /**
         * id напоминания
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/planner-notification/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Просмотр напоминания
     * Устанавливает статус напоминания как просмотренное
     * @returns any Статус успешно установлен
     * @throws ApiError
     */
    public static plannerNotificationView({
        id,
    }: {
        /**
         * id напоминания
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/planner-notification/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Добавление напоминания в планировщик
     * Добавляет напоминание в планировщик
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static plannerNotificationAdd({
        requestBody,
    }: {
        requestBody?: PlannerNotificationBasicSchema,
    }): CancelablePromise<{
        message?: string;
        notificationId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/planner-notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Список должностей
     * Возвращает отфильтрованный список должностей
     * @returns PostCollectionSchema Результат получен
     * @throws ApiError
     */
    public static postIndex({
        name,
    }: {
        /**
         * Название должности (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<PostCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/posts',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление должности
     * Добавляет должность в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static postAdd({
        requestBody,
    }: {
        requestBody?: PostAddSchema,
    }): CancelablePromise<{
        message?: string;
        postId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/post',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление должности
     * Обновляет указанную должность в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static postUpdate({
        id,
        requestBody,
    }: {
        /**
         * id должности
         */
        id: any,
        requestBody?: PostAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/post/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление должности
     * Удаляет указанную должность из справочника
     * @returns void
     * @throws ApiError
     */
    public static postDelete({
        id,
    }: {
        /**
         * id должности
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/post/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Прайс-лист детально
     * Возвращает данные прайс-листа
     * @returns any Результат получен
     * @throws ApiError
     */
    public static priceListGet({
        xRequestedWith,
        id,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
    }): CancelablePromise<{
        priceList?: PriceListSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pricelist/{id}/settings',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Список прайс-листов
     * Возвращает отфильтрованный список прайс-листов
     * @returns PriceListCollectionSchema Результат получен
     * @throws ApiError
     */
    public static priceListIndex({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: PriceListFilterSchema,
    }): CancelablePromise<PriceListCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pricelist',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Добавление прайс-листа
     * Добавляет прайс-лист
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static priceListAdd({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: (PriceListSchema & {
            /**
             * Идентификаторы типов ТС (массив или число)
             */
            vehicleTypes?: number;
            /**
             * Идентификаторы моделей автомобиля (массив или число)
             */
            carMakes?: number;
        }),
    }): CancelablePromise<{
        message?: string;
        priceListId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pricelist',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление прайс-листа
     * Обновляет прайс-лист
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static priceListUpdate({
        xRequestedWith,
        requestBody,
    }: {
        xRequestedWith: any,
        requestBody?: (PriceListSchema & {
            /**
             * Идентификаторы типов ТС (массив или число)
             */
            vehicleTypes?: number;
            /**
             * Идентификаторы моделей автомобиля (массив или число)
             */
            carMakes?: number;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pricelist/{id}',
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление прайс-листа
     * Удаляет указанный прайс-лист
     * @returns void
     * @throws ApiError
     */
    public static priceListDelete({
        xRequestedWith,
        id,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pricelist/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
        });
    }
    /**
     * Список элементов прайс-листа
     * Возвращает отфильтрованный список элементов прайс-листа
     * @returns PriceListItemCollectionSchema Результат получен
     * @throws ApiError
     */
    public static priceListItemIndex({
        xRequestedWith,
        id,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
        requestBody?: {
            filter?: PriceListItemFilterSchema;
        },
    }): CancelablePromise<PriceListItemCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/pricelist/{id}/items',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Удаление элементов прайс-листа
     * Удаляет указанные элементы прайс-лист
     * @returns void
     * @throws ApiError
     */
    public static priceListItemDelete({
        xRequestedWith,
        id,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
        requestBody?: {
            /**
             * Id элементов для удаления (можно передавать массив)
             */
            items?: number;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pricelist/{id}/items',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Добавление элемента прайс-листа
     * Добавляет элемент прайс-листа
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static priceListItemAdd({
        xRequestedWith,
        id,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
        requestBody?: PriceListItemAddSchema,
    }): CancelablePromise<{
        message?: string;
        itemId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pricelist/{id}/item',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Обновление элемента прайс-листа
     * Обновляет элемент прайс-листа
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static priceListItemUpdate({
        xRequestedWith,
        id,
        itemId,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
        /**
         * id элемента прайс-листа
         */
        itemId: any,
        requestBody?: PriceListItemAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/pricelist/{id}/item/{itemId}',
            path: {
                'id': id,
                'itemId': itemId,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Добавление раздела в прайс-лист
     * Добавляет раздел в указанный прайс-лист
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static priceListSectionAdd({
        xRequestedWith,
        id,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        sectionId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/pricelist/{id}/section',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление разделов прайс-листа
     * Удаляет указанные разделы прайс-лист
     * @returns void
     * @throws ApiError
     */
    public static priceListSectionDelete({
        xRequestedWith,
        id,
        requestBody,
    }: {
        xRequestedWith: any,
        /**
         * id прайс-листа
         */
        id: any,
        requestBody?: {
            /**
             * Id разделов для удаления (можно передавать массив)
             */
            sections?: number;
        },
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/pricelist/{id}/sections',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Список мест хранения
     * Возвращает отфильтрованный список мест хранения
     * @returns RackCollectionSchema Результат получен
     * @throws ApiError
     */
    public static rackIndex({
        id,
        name,
        storage,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по складу
         */
        storage?: any,
    }): CancelablePromise<RackCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/racks',
            query: {
                'id': id,
                'name': name,
                'storage': storage,
            },
        });
    }
    /**
     * Добавление места хранения
     * Добавляет место хранения
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static rackAdd({
        requestBody,
    }: {
        requestBody?: RackBasicSchema,
    }): CancelablePromise<{
        message?: string;
        rackId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rack',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление места хранения
     * Обновляет указанное место хранения
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static rackUpdate({
        id,
        requestBody,
    }: {
        /**
         * id места хранения
         */
        id: any,
        requestBody?: RackBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rack/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление места хранения
     * Удаляет указанное место хранения
     * @returns void
     * @throws ApiError
     */
    public static rackDelete({
        id,
    }: {
        /**
         * id места хранения
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/rack/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Данные кассы
     * Данные кассы
     * @returns any Успешно
     * @throws ApiError
     */
    public static registerGet({
        id,
    }: {
        /**
         * id кассы
         */
        id: any,
    }): CancelablePromise<{
        register?: RegisterSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/register/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Обновление кассы
     * Обновляет кассу
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static registerUpdate({
        id,
        requestBody,
    }: {
        /**
         * id кассы
         */
        id: any,
        requestBody?: RegisterBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/register/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление кассы
     * Удаляет кассу
     * @returns void
     * @throws ApiError
     */
    public static registerDelete({
        id,
    }: {
        /**
         * id кассы
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/register/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список касс
     * Возвращает отфильтрованный список касс
     * @returns any Результат получен
     * @throws ApiError
     */
    public static registerIndex({
        name,
        active,
        query,
        viewMode,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по активности
         */
        active?: any,
        /**
         * Поиск по следующим полям: name
         */
        query?: any,
        /**
         * Режим отображения
         */
        viewMode?: 'columns' | 'list',
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Кассы
         */
        items?: Array<(RegisterListBoxSchema | RegisterListViewSchema)>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/registers',
            query: {
                'name': name,
                'active': active,
                'query': query,
                'view_mode': viewMode,
            },
        });
    }
    /**
     * Добавление кассы
     * Добавляет кассу
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static registerAdd({
        requestBody,
    }: {
        requestBody?: RegisterBasicSchema,
    }): CancelablePromise<{
        message?: string;
        registerId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/register',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Активация кассы
     * Активирует кассу
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static registerOn({
        id,
    }: {
        /**
         * id кассы
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/register/{id}/on',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Деактивация кассы
     * Деактивирует кассу
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static registerOff({
        id,
    }: {
        /**
         * id кассы
         */
        id: any,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/register/{id}/off',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список ролей
     * Возвращает отфильтрованный список ролей
     * @returns RoleCollectionSchema Результат получен
     * @throws ApiError
     */
    public static roleIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<RoleCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление графика смен
     * Добавляет график смен
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static shiftCalendarAdd({
        requestBody,
    }: {
        requestBody?: ShiftCalendarAddSchema,
    }): CancelablePromise<{
        message?: string;
        shiftCalendarId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/shift-calendar',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Список складов
     * Возвращает отфильтрованный список складов
     * @returns StorageCollectionSchema Результат получен
     * @throws ApiError
     */
    public static storageIndex({
        id,
        name,
        location,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по локации
         */
        location?: any,
    }): CancelablePromise<StorageCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/storages',
            query: {
                'id': id,
                'name': name,
                'location': location,
            },
        });
    }
    /**
     * Добавление склада
     * Добавляет склад
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static storageAdd({
        requestBody,
    }: {
        requestBody?: StorageAddSchema,
    }): CancelablePromise<{
        message?: string;
        storageId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/storage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление склада
     * Обновляет указанный склад
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static storageUpdate({
        id,
        requestBody,
    }: {
        /**
         * id склада
         */
        id: any,
        requestBody?: StorageAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/storage/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление склада
     * Удаляет указанный склад
     * @returns void
     * @throws ApiError
     */
    public static storageDelete({
        id,
    }: {
        /**
         * id склада
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/storage/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список систем налогооблажения
     * Возвращает отфильтрованный список систем налогооблажения
     * @returns TaxationCollectionSchema Результат получен
     * @throws ApiError
     */
    public static taxationIndex({
        requestBody,
    }: {
        requestBody?: TaxationFilterSchema,
    }): CancelablePromise<TaxationCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/taxation',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Данные системы налогооблажения
     * Возвращает информацию об указанной системе налогооблажения
     * @returns any Данные получены
     * @throws ApiError
     */
    public static taxationGet({
        xRequestedWith,
        id,
    }: {
        xRequestedWith: any,
        /**
         * id системы налогооблажения
         */
        id: any,
    }): CancelablePromise<{
        taxation?: TaxationSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/taxation/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Requested-With': xRequestedWith,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Список часовых поясов
     * @returns any Результат получен
     * @throws ApiError
     */
    public static timeZoneIndex(): CancelablePromise<{
        /**
         * Массив результатов
         */
        items?: Array<TimeZoneTemplate>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/timezones',
        });
    }
    /**
     * Данные транзакции
     * Получает данные транзакции
     * @returns any Успешно
     * @throws ApiError
     */
    public static transactionGet({
        id,
    }: {
        /**
         * id транзакции
         */
        id: any,
    }): CancelablePromise<{
        transaction?: TransactionSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transaction/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Обновление транзакции
     * Обновляет транзакцию
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static transactionUpdate({
        id,
        formData,
    }: {
        /**
         * id транзакции
         */
        id: any,
        formData: (TransactionBasicSchema & UploadedFileSchema & {
            /**
             * Существующие файлы, которые необходимо сохранить
             */
            keep?: Array<KeepFileSchema>;
        }),
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transaction/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Отмена транзакции
     * Отменяет транзакцию
     * @returns void
     * @throws ApiError
     */
    public static transactionDelete({
        id,
    }: {
        /**
         * id транзакции
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/transaction/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список транзакций
     * Возвращает отфильтрованный список транзакций
     * @returns any Результат получен
     * @throws ApiError
     */
    public static transactionIndex({
        id,
        type,
        dateFrom,
        dateTo,
        register,
        transactionType,
        createdBy,
        query,
        page,
        limit,
    }: {
        /**
         * ID
         */
        id?: any,
        /**
         * Тип транзакции
         */
        type?: any,
        /**
         * Дата от
         */
        dateFrom?: any,
        /**
         * Дата до
         */
        dateTo?: any,
        /**
         * ID кассы
         */
        register?: any,
        /**
         * ID статьи доходов/расходов
         */
        transactionType?: any,
        /**
         * ID Ответственного
         */
        createdBy?: any,
        /**
         * Поиск по след. полям: Контрагент, ФИО ответственного, название статьи, название кассы, комментарий, сумма
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Транзакции
         */
        items?: Array<TransactionListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transactions',
            query: {
                'id': id,
                'type': type,
                'date[from]': dateFrom,
                'date[to]': dateTo,
                'register': register,
                'transaction_type': transactionType,
                'created_by': createdBy,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Добавление транзакции
     * Добавляет транзакцию
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static transactionAdd({
        formData,
    }: {
        formData: (TransactionBasicSchema & UploadedFileSchema),
    }): CancelablePromise<{
        message?: string;
        transactionId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transaction',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Статьи доходов/расходов
     * Возвращает отфильтрованный список статей доходов/расходов
     * @returns TransactionTypeCollectionSchema Результат получен
     * @throws ApiError
     */
    public static transactionTypeIndex({
        type,
        name,
    }: {
        /**
         * Фильтр по типу (приход/расход)
         */
        type?: 'income' | 'expense',
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<TransactionTypeCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transaction-types',
            query: {
                'type': type,
                'name': name,
            },
        });
    }
    /**
     * Добавление статьи доходов/расходов
     * Добавляет статью доходов/расходов
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static transactionTypeAdd({
        requestBody,
    }: {
        requestBody?: TransactionTypeBasicSchema,
    }): CancelablePromise<{
        message?: string;
        transactionTypeId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transaction-type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление статьи доходов/расходов
     * Обновляет статью доходов/расходов
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static transactionTypeUpdate({
        id,
        requestBody,
    }: {
        /**
         * id статьи
         */
        id: any,
        requestBody?: TransactionTypeBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/transaction-type/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление статьи доходов/расходов
     * Удаляет статью доходов/расходов
     * @returns void
     * @throws ApiError
     */
    public static transactionTypeDelete({
        id,
    }: {
        /**
         * id статьи
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/transaction-type/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Данные перемещения денежных средств
     * Получает данные перемещения денежных средств
     * @returns any Успешно
     * @throws ApiError
     */
    public static transferGet({
        id,
    }: {
        /**
         * id перемещения
         */
        id: any,
    }): CancelablePromise<{
        transfer?: TransferSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transfer/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Изменение перемещения денежных средств
     * Изменяет перемещение денежных средств
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static transferUpdate({
        id,
        requestBody,
    }: {
        /**
         * id перемещения
         */
        id: any,
        requestBody?: TransferBasicSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/transfer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление перемещения денежных средств
     * Удаляет перемещение денежных средств
     * @returns void
     * @throws ApiError
     */
    public static transferDelete({
        id,
    }: {
        /**
         * id перемещения
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/transfer/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список перемещений денежных средств
     * Возвращает отфильтрованный список перемещений денежных средств
     * @returns any Результат получен
     * @throws ApiError
     */
    public static transferIndex({
        id,
        dateFrom,
        dateTo,
        createdBy,
        query,
        page,
        limit,
    }: {
        /**
         * ID
         */
        id?: any,
        /**
         * Дата от
         */
        dateFrom?: any,
        /**
         * Дата до
         */
        dateTo?: any,
        /**
         * ID Ответственного
         */
        createdBy?: any,
        /**
         * Поиск по след. полям: ФИО ответственного, название кассы-отправителя, название кассы-получателя, комментарий, сумма
         */
        query?: any,
        /**
         * Номер страницы пагинации
         */
        page?: any,
        /**
         * Кол-во результатов на странице
         */
        limit?: any,
    }): CancelablePromise<{
        /**
         * Колонки
         */
        columns?: Array<ListColumnSchema>;
        /**
         * Перемещения денежных средств
         */
        items?: Array<TransferListViewSchema>;
        nav?: PaginatorTemplate;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transfers',
            query: {
                'id': id,
                'date[from]': dateFrom,
                'date[to]': dateTo,
                'created_by': createdBy,
                'query': query,
                'page': page,
                'limit': limit,
            },
        });
    }
    /**
     * Создание перемещения денежных средств
     * Создаёт перемещения денежных средств
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static transferAdd({
        requestBody,
    }: {
        requestBody?: TransferBasicSchema,
    }): CancelablePromise<{
        message?: string;
        transferId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transfer',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Справочник типов трансмиссии
     * Возвращает отфильтрованный список типов трансмиссии
     * @returns TransmissionCollectionSchema Результат получен
     * @throws ApiError
     */
    public static transmissionIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<TransmissionCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/transmissions',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление типа трансмиссии
     * Добавляет тип трансмиссии в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static transmissionAdd({
        requestBody,
    }: {
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        transmissionId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/transmission',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление типа трансмиссии
     * Обновляет указанный тип трансмиссии в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static transmissionUpdate({
        id,
        requestBody,
    }: {
        /**
         * id типа трансмиссии
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/transmission/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление типа трансмиссии
     * Удаляет указанный тип трансмиссии из справочника
     * @returns void
     * @throws ApiError
     */
    public static transmissionDelete({
        id,
    }: {
        /**
         * id типа трансмиссии
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/transmission/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Справочник единиц измерения
     * Возвращает отфильтрованный список единиц измерения
     * @returns UnitCollectionSchema Результат получен
     * @throws ApiError
     */
    public static unitIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<UnitCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/units',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление единицы измерения
     * Добавляет единицу измерения в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static unitAdd({
        requestBody,
    }: {
        requestBody?: {
            name: string;
            short_name?: string;
        },
    }): CancelablePromise<{
        message?: string;
        unitId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/unit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление единицы измерения
     * Обновляет указанную единицу измерения в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static unitUpdate({
        id,
        requestBody,
    }: {
        /**
         * id единицы измерения
         */
        id: any,
        requestBody?: {
            name: string;
            short_name?: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/unit/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление единицы измерения
     * Удаляет указанную единицу измерения из справочника
     * @returns void
     * @throws ApiError
     */
    public static unitDelete({
        id,
    }: {
        /**
         * id единицы измерения
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/unit/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список пользователей
     * Возвращает отфильтрованный список пользователей
     * @returns any Результат получен
     * @throws ApiError
     */
    public static userIndex({
        id,
        email,
        role,
        post,
        query,
        viewMode,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по email (поиск подстроки)
         */
        email?: any,
        /**
         * Фильтр по идентификатору роли
         */
        role?: any,
        /**
         * Фильтр по идентификатору должности
         */
        post?: any,
        /**
         * Ищет вхождение указанной подстроки в след. полях: имя, фамилия, отчество, телефон, email
         */
        query?: any,
        /**
         * Режим отображения
         */
        viewMode?: 'alphabet' | 'list',
    }): CancelablePromise<{
        items?: Array<(UserAlphabetListSchema | UserDataTemplate)>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            query: {
                'id': id,
                'email': email,
                'role': role,
                'post': post,
                'query': query,
                'view_mode': viewMode,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Первоначальное создание админа
     * Создаёт админа, если не существует
     * @returns any Успешная регистрация
     * @throws ApiError
     */
    public static userCreateAdmin({
        requestBody,
    }: {
        requestBody: UserCreateAdminSchema,
    }): CancelablePromise<{
        message?: string;
        /**
         * auth-токен (время жизни 1 час)
         */
        access_token?: string;
        /**
         * refresh-токен (время жизни 30 дней)
         */
        refresh_token?: string;
        /**
         * Тип токена
         */
        token_type?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/admin',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Авторизация пользователя
     * Авторизует пользователя и возвращает токены
     * @returns UserAuthorizedSchema Успешная авторизация
     * @throws ApiError
     */
    public static userLogin({
        requestBody,
    }: {
        requestBody: {
            email: string;
            /**
             * 123uR4737
             */
            password: string;
        },
    }): CancelablePromise<UserAuthorizedSchema> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Ошибка авторизации`,
            },
        });
    }
    /**
     * Завершение сеанса
     * Завершение сеанса
     * @returns any Успешно
     * @throws ApiError
     */
    public static userLogout(): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/logout',
            errors: {
                401: `Ошибка`,
            },
        });
    }
    /**
     * Получение нового auth-токена
     * Возвращает новый auth-токен, используя refresh-токен
     * @returns any Получен новый auth-токен
     * @throws ApiError
     */
    public static userRefreshToken(): CancelablePromise<{
        /**
         * auth-токен (время жизни 1 час)
         */
        access_token?: string;
        /**
         * Тип токена
         */
        token_type?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/refresh-token',
            errors: {
                401: `Ошибка`,
            },
        });
    }
    /**
     * Добавление пользователя
     * Добавляет пользователя
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static userAdd({
        requestBody,
    }: {
        requestBody?: UserAddSchema,
    }): CancelablePromise<{
        message?: string;
        userId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Информация о пользователе
     * Возвращает данные указанного пользователя
     * @returns any Пользователь найден
     * @throws ApiError
     */
    public static userGet({
        id,
    }: {
        /**
         * id пользователя
         */
        id: any,
    }): CancelablePromise<{
        user?: UserSchema;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Обновление данных пользователя
     * Обновляет данные указанного пользователя
     * @returns any Успешное выполнение
     * @throws ApiError
     */
    public static userUpdate({
        id,
        requestBody,
    }: {
        /**
         * id пользователя
         */
        id: any,
        requestBody?: UserAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Ошибка`,
            },
        });
    }
    /**
     * Устанавливает аватар пользователя
     * Устанавливает аватар пользователя
     * @returns any Аватар успешно сохранён
     * @throws ApiError
     */
    public static userSetAvatar({
        id,
        formData,
    }: {
        /**
         * id пользователя
         */
        id: any,
        formData: {
            /**
             * Файл изображения
             */
            avatar: Blob;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/{id}/avatar',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }
    /**
     * Справочник типов транспортного средства
     * Возвращает отфильтрованный список типов транспортного средства
     * @returns VehicleTypeCollectionSchema Результат получен
     * @throws ApiError
     */
    public static vehicleTypeIndex({
        name,
    }: {
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
    }): CancelablePromise<VehicleTypeCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/vehicle-types',
            query: {
                'name': name,
            },
        });
    }
    /**
     * Добавление типа транспортного средства
     * Добавляет тип транспортного средства в справочник
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static vehicleTypeAdd({
        requestBody,
    }: {
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
        vehicleTypeId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/vehicle-type',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление типа транспортного средства
     * Обновляет указанный тип транспортного средства в справочнике
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static vehicleTypeUpdate({
        id,
        requestBody,
    }: {
        /**
         * id типа транспортного средства
         */
        id: any,
        requestBody?: {
            name: string;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/vehicle-type/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление типа транспортного средства
     * Удаляет указанный тип транспортного средства из справочника
     * @returns void
     * @throws ApiError
     */
    public static vehicleTypeDelete({
        id,
    }: {
        /**
         * id типа транспортного средства
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/vehicle-type/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список рабочих мест
     * Возвращает отфильтрованный список рабочих мест
     * @returns WorkPlaceCollectionSchema Результат получен
     * @throws ApiError
     */
    public static workPlaceIndex({
        id,
        name,
        workZone,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по рабочей зоне
         */
        workZone?: any,
    }): CancelablePromise<WorkPlaceCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/work-place',
            query: {
                'id': id,
                'name': name,
                'workZone': workZone,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Добавление рабочего места
     * Добавляет рабочее место
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static workPlaceAdd({
        requestBody,
    }: {
        requestBody?: WorkPlaceAddSchema,
    }): CancelablePromise<{
        message?: string;
        workPlaceId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/work-place',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление рабочего места
     * Обновляет указанное рабочее место
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static workPlaceUpdate({
        id,
        requestBody,
    }: {
        /**
         * id рабочего места
         */
        id: any,
        requestBody?: WorkPlaceAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/work-place/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление рабочего места
     * Удаляет указанное рабочее место
     * @returns void
     * @throws ApiError
     */
    public static workPlaceDelete({
        id,
    }: {
        /**
         * id рабочего места
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/work-place/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Список рабочих зон
     * Возвращает отфильтрованный список рабочих зон
     * @returns WorkZoneCollectionSchema Результат получен
     * @throws ApiError
     */
    public static workZoneIndex({
        id,
        name,
        location,
    }: {
        /**
         * Фильтр по id
         */
        id?: any,
        /**
         * Фильтр по названию (поиск подстроки)
         */
        name?: any,
        /**
         * Фильтр по локации
         */
        location?: any,
    }): CancelablePromise<WorkZoneCollectionSchema> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/work-zone',
            query: {
                'id': id,
                'name': name,
                'location': location,
            },
            errors: {
                404: `Ошибка`,
            },
        });
    }
    /**
     * Добавление рабочей зоны
     * Добавляет рабочую зону
     * @returns any Успешное добавление
     * @throws ApiError
     */
    public static workZoneAdd({
        requestBody,
    }: {
        requestBody?: WorkZoneAddSchema,
    }): CancelablePromise<{
        message?: string;
        workZoneId?: number;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/work-zone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Обновление рабочей зоны
     * Обновляет указанную рабочую зону
     * @returns any Успешное обновление
     * @throws ApiError
     */
    public static workZoneUpdate({
        id,
        requestBody,
    }: {
        /**
         * id рабочей зоны
         */
        id: any,
        requestBody?: WorkZoneAddSchema,
    }): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/work-zone/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Удаление рабочей зоны
     * Удаляет указанную рабочую зону
     * @returns void
     * @throws ApiError
     */
    public static workZoneDelete({
        id,
    }: {
        /**
         * id рабочей зоны
         */
        id: any,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/work-zone/{id}',
            path: {
                'id': id,
            },
        });
    }
}
